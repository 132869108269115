import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './component/Navbar';  // Navbar 컴포넌트 불러오기
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import ReviewPage from './pages/ReviewPage';
import AddReviewPage from './pages/AddReviewPage';
import ReviewDetailPage from './pages/ReviewDetailPage';
import PasswordUpdateForm from './pages/admin/PasswordUpdateForm';
import AdminLogin from './pages/admin/AdmimLogin';
import AdminDashboard from './pages/admin/AdminDashboard';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // 로그인 상태를 토큰을 통해 확인
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  // 로그아웃 함수
  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <Router>
      {/* Navbar에 로그인 상태와 로그아웃 함수 전달 */}
      <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/review" element={<ReviewPage />} />
        <Route path="/add-review" element={<AddReviewPage />} />
        <Route path="/reviews/:id" element={<ReviewDetailPage />} />
        <Route path="/passwordupdateform" element={<PasswordUpdateForm />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/admindashboard" element={<AdminDashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
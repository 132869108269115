import React, { useState , useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi'; // 햄버거 아이콘 가져오기

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px; /* 패딩 값을 줄여 높이 조정 */
  background-color: #0056b3;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 8px 15px; /* 모바일에서의 패딩도 줄임 */
  }
`;

const Logo = styled.div`
  margin-left: 60px;
  font-size: 1.6rem; /* 로고 크기 약간 줄임 */
  font-weight: bold;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-left: 10px;
    font-size: 1.3rem;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 15px;
  margin-right: 20px;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')}; /* 버튼 그룹의 표시 여부 */
    flex-direction: column; /* 세로 방향으로 배열 */
    position: absolute;
    top: 60px; /* 네비게이션 바 아래 위치 */
    right: 20px;
    background-color: #007bff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const Button = styled.button`
  font-weight: bold;
  background-color: transparent;
  color: white;
  padding: 10px 18px; /* 패딩을 약간 줄임 */
  font-size: 1rem;
  border: 2px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  &:hover {
    background-color: white;
    color: #007bff;
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
`;
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // 초기 로그인 상태 확인
    checkLoginStatus();

    // 로그인 상태 변경 이벤트 리스너
    window.addEventListener('loginStateChanged', checkLoginStatus);

    return () => {
      window.removeEventListener('loginStateChanged', checkLoginStatus);
    };
  }, []);

  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/');
  };

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <NavbarContainer>
      <Logo onClick={() => navigate('/')}>Semoi</Logo>
      <HamburgerIcon onClick={toggleMenu}>
        <FiMenu size={24} color="white" />
      </HamburgerIcon>
      <ButtonGroup isOpen={isOpen}>
        {isLoggedIn ? (
          <>
            <Button onClick={handleLogout}>로그아웃</Button>
            <Button onClick={() => navigate('/dashboard')}>마이페이지</Button>
          </>
        ) : (
          <>
            <Button onClick={() => navigate('/login')}>로그인</Button>
            <Button onClick={() => navigate('/register')}>회원가입</Button>
          </>
        )}
      </ButtonGroup>
    </NavbarContainer>
  );
};

export default Navbar;
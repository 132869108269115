import React from 'react';
import { useNavigate } from 'react-router-dom'; // 추가된 부분
import styled, { keyframes } from 'styled-components';
import { FaInstagram, FaAppStoreIos, FaGooglePlay } from 'react-icons/fa'; // AppStore 로고 변경

// 배경에 애니메이션을 추가
const backgroundAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background: linear-gradient(45deg, #0056b3, #89cff0, #f5f5f5); /* 다크 블루와 잘 어울리는 색상들 */
  background-size: 200% 200%;
  animation: ${backgroundAnimation} 10s ease infinite; /* 애니메이션 효과 */
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.5rem;
  text-align: center;
  color: #f1f1f1;
  max-width: 600px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const IconGroup = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${({ gradient }) => gradient};
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1); /* 호버 시 확대 효과 */
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

const Icon = styled.a`
  color: #ffffff;
  font-size: 2rem; /* 아이콘의 크기를 조정 */
  display: flex;
  justify-content: center;
  align-items: center; /* 아이콘이 원 안에 중앙 정렬되도록 설정 */
  width: 100%; /* 부모 컨테이너 크기에 맞춰 중앙 정렬 */
  height: 100%; /* 부모 컨테이너 크기에 맞춰 중앙 정렬 */

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

// 버튼 스타일링 추가
const ReviewButton = styled.button`
  font-weight: bolder;
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #ffffff;
  background: linear-gradient(45deg, #2196F3, #00B0FF); /* 파란색 그라데이션 */
  border: none;
  border-radius: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 80px;

  &:hover {
    transform: translateY(-5px); /* 호버 시 버튼 살짝 위로 이동 */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* 호버 시 그림자 강화 */
  }

  &:active {
    transform: translateY(-2px); /* 클릭 시 버튼 살짝 덜 올라감 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 클릭 시 그림자 조정 */
  }

  @media (max-width: 768px) {
    padding: 10px 25px;
    font-size: 1rem;
  }
`;

const Home = () => {
  const navigate = useNavigate(); // useNavigate 훅 추가

  return (
    <HomeContainer>
      <Title>세상의 모든 물건을 리뷰하다!</Title>
      <Description>
        당신 주변에 좋은 물건들을 리뷰하고 공유해보세요!
      </Description>
      <IconGroup>
        <IconContainer gradient="linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888)">
          <Icon href="#" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </Icon>
        </IconContainer>
        <IconContainer gradient="linear-gradient(45deg, #c0c0c0, #0056b3)">
          <Icon href="#" target="_self" rel="noopener noreferrer">
            <FaAppStoreIos /> 
          </Icon>
        </IconContainer>
        <IconContainer gradient="linear-gradient(45deg, #34a853, #fbbc05)">
          <Icon href="#" target="_self" rel="noopener noreferrer">
            <FaGooglePlay />
          </Icon>
        </IconContainer>
      </IconGroup>
      <ReviewButton onClick={() => navigate('/review')}>리뷰하러 가기</ReviewButton> {/* 버튼 추가 */}
    </HomeContainer>
  );
};

export default Home;
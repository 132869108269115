import React, { useState } from 'react';
import axios from 'axios';

function PasswordUpdateForm() {
  const [username, setUsername] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:4000/api/update-admin-password', {
        username,
        currentPassword,
        newPassword
      });
      alert(response.data.message);
    } catch (error) {
      alert('비밀번호 업데이트 실패');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="사용자명"
        required
      />
      <input
        type="password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        placeholder="현재 비밀번호"
        required
      />
      <input
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        placeholder="새 비밀번호"
        required
      />
      <button type="submit">비밀번호 업데이트</button>
    </form>
  );
}

export default PasswordUpdateForm;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaHeart, FaStar, FaPlus, FaSearch } from 'react-icons/fa';
import AddReviewPage from './AddReviewPage';

const DEFAULT_IMAGE_URL = 'https://via.placeholder.com/300x300?text=No+Image';

const ReviewPageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fafafa;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #262626;
`;

const AddReviewButton = styled.button`
  background-color: #0095f6;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0081d6;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Select = styled.select`
  padding: 8px 12px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
`;

const SearchInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  font-size: 14px;
  width: 200px;
`;

const ReviewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 28px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

const ReviewCard = styled.div`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ReviewImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const ReviewContent = styled.div`
  padding: 16px;
`;

const ReviewTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #262626;
`;

const ReviewExcerpt = styled.p`
  font-size: 14px;
  color: #8e8e8e;
  margin-bottom: 12px;
`;

const ReviewMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #8e8e8e;
`;

const LoadingMessage = styled.p`
  text-align: center;
  font-size: 18px;
  color: #8e8e8e;
  margin-top: 40px;
`;

const ErrorMessage = styled.p`
  text-align: center;
  font-size: 18px;
  color: #ed4956;
  margin-top: 40px;
`;

const categories = [
  { _id: 'all', name: '전체' },
  { _id: '1', name: '전자제품' },
  { _id: '2', name: '의류' },
  { _id: '3', name: '식품' },
  { _id: '4', name: '자동차' },
  { _id: '5', name: '기타' },
];

const ReviewPage = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddingReview, setIsAddingReview] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sortOption, setSortOption] = useState('latest');
  const [categoryOption, setCategoryOption] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
    return token;
  };

  const getImageUrl = (imagePath) => {
    return imagePath ? `/${imagePath.replace(/\\/g, '/')}` : DEFAULT_IMAGE_URL;
  };

  const fetchReviews = async () => {
    const token = checkLoginStatus();
    try {
      const response = await fetch(`/api/reviews?category=${categoryOption}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : '',
        },
      });

      if (response.status === 401) {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setError('세션이 만료되었습니다. 다시 로그인해주세요.');
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setReviews(data);
      setError(null);
    } catch (error) {
      setError('리뷰 가져오기 오류: ' + error.message);
      console.error('리뷰 가져오기 오류:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [categoryOption, sortOption]);

  const getLikesCount = (review) => {
    if (Array.isArray(review.likes)) {
      return review.likes.length;
    }
    if (typeof review.likes === 'number') {
      return review.likes;
    }
    return 0;
  };

  const handleAddReview = () => {
    if (isLoggedIn) {
      setIsAddingReview(true);
    } else {
      navigate('/login');
    }
  };

  const handleReviewAdded = () => {
    setIsAddingReview(false);
    fetchReviews();
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategoryOption(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleReviewClick = (reviewId) => {
    navigate(`/reviews/${reviewId}`);
  };

  const filteredAndSortedReviews = reviews
  .filter((review) => {
    const matchesCategory = categoryOption === 'all' || review.category === categoryOption;
    const matchesSearch = review.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          review.content.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearch;
  })
  .sort((a, b) => {
    if (sortOption === 'latest') {
      return new Date(b.createdAt) - new Date(a.createdAt);
    } else if (sortOption === 'rating') {
      return (b.rating || 0) - (a.rating || 0);
    } else if (sortOption === 'popular') {
      return getLikesCount(b) - getLikesCount(a);
    }
    return 0;
  });

  return (
    <ReviewPageContainer>
      <Header>
        <Title>리뷰</Title>
        {isLoggedIn && (
          <AddReviewButton onClick={handleAddReview}>
            <FaPlus /> 리뷰 작성
          </AddReviewButton>
        )}
      </Header>

      {isAddingReview ? (
        <AddReviewPage onReviewAdded={handleReviewAdded} />
      ) : (
        <>
          <FilterContainer>
            <div>
              <Select onChange={handleSortChange} value={sortOption}>
                <option value="latest">최신순</option>
                <option value="rating">별점순</option>
                <option value="popular">인기순</option>
              </Select>
              <Select onChange={handleCategoryChange} value={categoryOption}>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </Select>
            </div>
            <SearchInput
              type="text"
              placeholder="리뷰 검색..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </FilterContainer>

          {loading ? (
            <LoadingMessage>로딩 중...</LoadingMessage>
          ) : error ? (
            <ErrorMessage>{error}</ErrorMessage>
          ) : (
            <ReviewGrid>
              {filteredAndSortedReviews.map((review) => (
                <ReviewCard key={review._id} onClick={() => handleReviewClick(review._id)}>
                    <ReviewImage
                        src={review.images && review.images.length > 0
                        ? getImageUrl(review.images[0])
                        : DEFAULT_IMAGE_URL}
                        alt={review.title}
                        onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = DEFAULT_IMAGE_URL;
              }}
            />
                  <ReviewContent>
                    <ReviewTitle>{review.title}</ReviewTitle>
                    <ReviewExcerpt>{review.content.substring(0, 100)}...</ReviewExcerpt>
                    <ReviewMeta>
                      <span><FaStar /> {review.rating ? review.rating.toFixed(1) : 'N/A'}</span>
                      <span><FaHeart /> {getLikesCount(review)}</span>
                  </ReviewMeta>
                  </ReviewContent>
                </ReviewCard>
              ))}
            </ReviewGrid>
          )}
        </>
      )}
    </ReviewPageContainer>
  );
};

export default ReviewPage;
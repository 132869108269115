import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { FaUser,FaHeart, FaRegHeart, FaEdit, FaTrash, FaComment , FaPaperPlane, FaFlag} from 'react-icons/fa';

const DEFAULT_IMAGE_URL = 'https://via.placeholder.com/300x300?text=No+Image';

const DetailContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    margin: 1rem;
    padding: 1rem;
  }
`;

const DetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const AuthorName = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
  color: #333;
`;

const PostDate = styled.span`
  font-size: 0.9rem;
  color: #777;
`;

const DetailTitle = styled.h1`
  font-size: 2.2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const ReviewImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

const DetailContent = styled.p`
  font-size: 1.1rem;
  color: #34495e;
  line-height: 1.6;
  margin-bottom: 2rem;
`;

const DetailMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
`;

const DetailRating = styled.div`
  font-size: 1.2rem;
  color: #f39c12;
  display: flex;
  align-items: center;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #f8f9fa;
  color: #333;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e9ecef;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const LikeButton = styled(ActionButton)`
  background-color: ${({ hasLiked }) => (hasLiked ? '#e74c3c' : '#3498db')};
  color: white;

  &:hover {
    background-color: ${({ hasLiked }) => (hasLiked ? '#c0392b' : '#2980b9')};
  }
`;

const DeleteButton = styled(ActionButton)`
  background-color: #e74c3c;
  color: white;

  &:hover {
    background-color: #c0392b;
  }
`;

const ReportButton = styled(ActionButton)`
  background-color: #f1c40f;
  color: #333;

  &:hover {
    background-color: #f39c12;
  }
`;

const CommentContainer = styled.div`
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #e0e0e0;
`;

const CommentForm = styled.form`
  display: flex;
  margin-bottom: 1.5rem;
`;

const CommentInput = styled.input`
  flex-grow: 1;
  padding: 0.7rem 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #3498db;
  }
`;

const SubmitCommentButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 1rem;

  &:hover {
    background-color: #27ae60;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const CommentList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CommentItem = styled.li`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const CommentAuthor = styled.div`
  font-weight: bold;
  color: #3498db;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CommentAuthorName = styled.span`
  color: #7f8c8d;
  font-size: 0.9em;
  margin-left: 0.5rem;
`;

const CommentContent = styled.p`
  margin-bottom: 0.5rem;
`;

const CommentActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CommentActionButton = styled.button`
  background: none;
  border: none;
  color: #7f8c8d;
  font-size: 0.9rem;
  cursor: pointer;
  margin-left: 1rem;
  transition: color 0.3s ease;

  &:hover {
    color: #3498db;
  }
`;

const CommentDate = styled.span`
  font-size: 0.8rem;
  color: #7f8c8d;
`;

const LoadingSkeleton = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  margin: 10px 0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

const ErrorMessage = styled.p`
  color: #e74c3c;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 2rem;
`;

const ReviewDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [reviewDetail, setReviewDetail] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentContent, setEditCommentContent] = useState('');
  const [likes, setLikes] = useState(0);
  const [hasLiked, setHasLiked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthor, setIsAuthor] = useState(false);
  const [reportReason, setReportReason] = useState('');

  const getImageUrl = (imagePath) => {
    return imagePath ? `/${imagePath.replace(/\\/g, '/')}` : DEFAULT_IMAGE_URL;
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('ko-KR', options);
  };

  const handleReportReview = async () => {
    const reason = prompt("신고 사유를 입력해주세요:");
    if (!reason) return;

    const token = localStorage.getItem('token');
    if (!token) {
      alert("신고하려면 로그인해야 합니다.");
      return;
    }

    try {
      const response = await fetch(`/api/reviews/${id}/report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ reason }),
      });

      if (!response.ok) {
        throw new Error('신고 실패');
      }

      alert('신고가 접수되었습니다.');
    } catch (error) {
      console.error('신고 오류:', error);
      alert(`신고 오류: ${error.message}`);
    }
  };

  const handleReportComment = async (commentId) => {
    const reason = prompt("신고 사유를 입력해주세요:");
    if (!reason) return;

    const token = localStorage.getItem('token');
    if (!token) {
      alert("신고하려면 로그인해야 합니다.");
      return;
    }

    try {
      const response = await fetch(`/api/reviews/${id}/comments/${commentId}/report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ reason }),
      });

      if (!response.ok) {
        throw new Error('신고 실패');
      }

      alert('신고가 접수되었습니다.');
    } catch (error) {
      console.error('신고 오류:', error);
      alert(`신고 오류: ${error.message}`);
    }
  };

  const fetchReviewDetail = async () => {
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      const headers = {
        'Content-Type': 'application/json',
      };

      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      const response = await fetch(`/api/reviews/${id}`, {
        method: 'GET',
        headers: headers,
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('로그인이 필요합니다. 다시 로그인해 주세요.');
        } else if (response.status === 403) {
          throw new Error('이 리뷰를 볼 수 있는 권한이 없습니다.');
        }
        throw new Error(`서버 오류가 발생했습니다. 상태 코드: ${response.status}`);
      }

      const data = await response.json();

      if (data) {
        setReviewDetail(data);
        setLikes(data.likes ? data.likes.length : 0);
        // 댓글을 날짜순으로 정렬
        const sortedComments = (data.comments || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setComments(sortedComments);
        setCurrentUser({ 
          id: data.currentUser,
          name: data.currentUserName,
          username: data.currentUserUsername
        });
        setHasLiked(data.hasLiked);
        setIsAuthor(data.currentUser === data.userId._id);
      } else {
        throw new Error('리뷰 정보가 없습니다.');
      }

    } catch (error) {
      console.error('Error fetching review detail:', error);
      setError(`리뷰 정보를 가져오는데 실패했습니다: ${error.message}`);
      if (error.message.includes('로그인이 필요합니다')) {
        navigate('/login', { state: { from: `/reviews/${id}` } });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async () => {
    if (hasLiked) return;
    const token = localStorage.getItem('token');
    if (!token) {
      alert("좋아요를 누르려면 로그인해야 합니다.");
      return;
    }

    try {
      const response = await fetch(`/api/reviews/${id}/like`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('좋아요 추가 실패');
      }
      const { newLikes, hasLiked: newHasLiked } = await response.json();
      setLikes(newLikes);
      setHasLiked(newHasLiked);
    } catch (error) {
      console.error('좋아요 추가 오류:', error);
    }
  };

  const handleDeleteReview = async () => {
    if (window.confirm('정말로 이 리뷰를 삭제하시겠습니까?')) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert("리뷰를 삭제하려면 로그인해야 합니다.");
        return;
      }
  
      try {
        const response = await fetch(`/api/reviews/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || '리뷰 삭제 실패');
        }
  
        const data = await response.json();
        alert(data.message);  // '리뷰가 성공적으로 삭제되었습니다.' 메시지 표시
        navigate('/review');  
      } catch (error) {
        console.error('리뷰 삭제 오류:', error);
        alert(`리뷰 삭제 오류: ${error.message}`);
      }
    }
  };

  const handleSubmitComment = async () => {
    if (!newComment) return;

    const token = localStorage.getItem('token');
    if (!token) {
      alert("댓글을 남기려면 로그인해야 합니다.");
      return;
    }

    try {
      const response = await fetch(`/api/reviews/${id}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ content: newComment }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '댓글 추가 실패');
      }

      // 댓글 추가 성공 후 리뷰 상세 정보를 다시 불러옵니다.
      await fetchReviewDetail();
      setNewComment('');
    } catch (error) {
      console.error('댓글 추가 오류:', error);
      alert(`댓글 추가 오류: ${error.message}`);
    }
  };

  const handleEditComment = async (commentId, newContent) => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert("댓글을 수정하려면 로그인해야 합니다.");
      return;
    }
    try {
      const response = await fetch(`/api/reviews/${id}/comments/${commentId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ content: newContent }),
      });
      if (!response.ok) {
        throw new Error('댓글 수정 실패');
      }
      const { comment: updatedComment } = await response.json();
      
      // 댓글 수정 성공 후 처리
      alert('댓글이 성공적으로 수정되었습니다.');
      
      // 리뷰 상세 정보를 다시 불러오기
      await fetchReviewDetail();
      
      // 편집 모드 종료
      setEditingCommentId(null);
      setEditCommentContent('');
    } catch (error) {
      console.error('댓글 수정 오류:', error);
      alert(`댓글 수정 오류: ${error.message}`);
    }
  };


  const handleDeleteComment = async (commentId) => {
    if (window.confirm('정말 이 댓글을 삭제하시겠습니까?')) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert("댓글을 삭제하려면 로그인해야 합니다.");
        return;
      }

      try {
        const response = await fetch(`/api/reviews/${id}/comments/${commentId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('댓글 삭제 실패');
        }

        setComments(prevComments => prevComments.filter(comment => comment._id !== commentId));
      } catch (error) {
        console.error('댓글 삭제 오류:', error);
        alert(`댓글 삭제 오류: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    fetchReviewDetail();
  }, [id]);

  if (loading) {
    return (
      <DetailContainer>
        <LoadingSkeleton />
        <LoadingSkeleton />
        <LoadingSkeleton />
        <LoadingSkeleton />
      </DetailContainer>
    );
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  return (
    <DetailContainer>
    {reviewDetail ? (
      <>
        <DetailHeader>
          <AuthorName>{reviewDetail.userId?.name || '알 수 없음'}</AuthorName>
          <PostDate>{formatDate(reviewDetail.createdAt)}</PostDate>
        </DetailHeader>
        <DetailTitle>{reviewDetail.title}</DetailTitle>
        {reviewDetail.images && reviewDetail.images.length > 0 && (
          <ImageContainer>
            {reviewDetail.images.map((image, index) => (
              <ReviewImage key={index} src={getImageUrl(image)} alt={`Review image ${index + 1}`} />
            ))}
          </ImageContainer>
        )}
        <DetailContent>{reviewDetail.content}</DetailContent>
        <DetailMeta>
          <DetailRating>⭐ {reviewDetail.rating.toFixed(1)}</DetailRating>
          <LikeButton onClick={handleLike} hasLiked={hasLiked} disabled={hasLiked}>
            {hasLiked ? <FaHeart /> : <FaRegHeart />}
            {likes} Likes
          </LikeButton>
          {isAuthor && (
            <DeleteButton onClick={handleDeleteReview}>
              <FaTrash /> 리뷰 삭제
            </DeleteButton>
          )}
          <ReportButton onClick={handleReportReview}>
            <FaFlag /> 리뷰 신고
          </ReportButton>
        </DetailMeta>
        <CommentContainer>
          <h3>댓글</h3>
          <CommentForm onSubmit={(e) => { e.preventDefault(); handleSubmitComment(); }}>
            <CommentInput
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="댓글을 남겨주세요..."
            />
            <SubmitCommentButton type="submit">
              <FaPaperPlane /> 댓글 남기기
            </SubmitCommentButton>
          </CommentForm>
          <CommentList>
            {comments.map((comment) => (
              <CommentItem key={comment._id}>
                <CommentAuthor>
                  <div>
                    {comment.author?.username || '사용자'}
                    <CommentAuthorName>({comment.author?.name || '알 수 없음'})</CommentAuthorName>
                  </div>
                  <CommentDate>{formatDate(comment.createdAt)}</CommentDate>
                </CommentAuthor>
                {editingCommentId === comment._id ? (
                  <>
                    <CommentInput
                      value={editCommentContent}
                      onChange={(e) => setEditCommentContent(e.target.value)}
                    />
                    <CommentActions>
                      <CommentActionButton onClick={() => handleEditComment(comment._id, editCommentContent)}>
                        수정 완료
                      </CommentActionButton>
                      <CommentActionButton onClick={() => setEditingCommentId(null)}>
                        취소
                      </CommentActionButton>
                    </CommentActions>
                  </>
                ) : (
                  <>
                    <CommentContent>{comment.content}</CommentContent>
                    <CommentActions>
                      {currentUser && currentUser.id === comment.author._id && (
                        <>
                          <CommentActionButton onClick={() => {
                            setEditingCommentId(comment._id);
                            setEditCommentContent(comment.content);
                          }}>
                            수정
                          </CommentActionButton>
                          <CommentActionButton onClick={() => handleDeleteComment(comment._id)}>
                            삭제
                          </CommentActionButton>
                        </>
                      )}
                      <CommentActionButton onClick={() => handleReportComment(comment._id)}>
                        신고
                      </CommentActionButton>
                    </CommentActions>
                  </>
                )}
              </CommentItem>
            ))}
          </CommentList>
        </CommentContainer>
      </>
    ) : (
      <ErrorMessage>리뷰를 찾을 수 없습니다.</ErrorMessage>
    )}
  </DetailContainer>
  );
};

export default ReviewDetailPage;
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';

// 스타일 컴포넌트 정의
const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  padding: 10px 20px;
  background-color: ${props => props.active ? '#007bff' : '#f0f0f0'};
  color: ${props => props.active ? 'white' : 'black'};
  border: none;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ContentManagement = () => {
  const [activeTab, setActiveTab] = useState('reviews');
  const [reviews, setReviews] = useState([]);
  const [comments, setComments] = useState([]);

  const fetchReviews = useCallback(async () => {
    const token = localStorage.getItem('adminToken');
    try {
      const response = await axios.get('/api/admin/all-reviews', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setReviews(response.data);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  }, []);

  const fetchComments = useCallback(async () => {
    const token = localStorage.getItem('adminToken');
    try {
      console.log('Fetching comments...');
      const response = await axios.get('/api/admin/all-comments', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Comments data:', response.data);
      
      if (Array.isArray(response.data)) {
        const formattedComments = response.data.map(comment => ({
          ...comment,
          review: comment.review?._id || comment.review
        }));
        setComments(formattedComments);
        console.log('Formatted comments:', formattedComments);
      } else {
        console.error('Unexpected response format:', response.data);
        setComments([]);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      }
      setComments([]);
      alert('댓글을 불러오는 데 실패했습니다.');
    }
  }, []);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);
  
  useEffect(() => {
    if (activeTab === 'reviews') {
      fetchReviews();
    } else {
      fetchComments();
    }
  }, [activeTab, fetchReviews, fetchComments]);

  const handleDelete = useCallback(async (id, type) => {
    const token = localStorage.getItem('adminToken');
    if (window.confirm(`정말로 이 ${type}을(를) 삭제하시겠습니까?`)) {
      try {
        let response;
        if (type === 'review') {
          response = await axios.delete(`/api/admin/reviews/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
        } else if (type === 'comment') {
          // 댓글 삭제 시 review ID가 필요할 수 있습니다.
          // 이 정보를 comments 상태에 포함시켜야 합니다.
          const comment = comments.find(c => c._id === id);
          if (!comment || !comment.review) {
            throw new Error('댓글 또는 리뷰 정보를 찾을 수 없습니다.');
          }
          response = await axios.delete(`/api/admin/reviews/${comment.review}/comments/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
        } else {
          throw new Error('알 수 없는 컨텐츠 타입입니다.');
        }
  
        if (response.status === 200) {
          alert(`${type}이(가) 삭제되었습니다.`);
          if (type === 'review') {
            fetchReviews();
          } else {
            fetchComments();
          }
        } else {
          throw new Error(`${type} 삭제에 실패했습니다.`);
        }
      } catch (error) {
        console.error(`Error deleting ${type}:`, error);
        alert(`${type} 삭제 중 오류가 발생했습니다: ${error.message}`);
      }
    }
  }, [fetchReviews, fetchComments, comments]);

  return (
    <div>
      <TabContainer>
        <Tab active={activeTab === 'reviews'} onClick={() => setActiveTab('reviews')}>리뷰</Tab>
        <Tab active={activeTab === 'comments'} onClick={() => setActiveTab('comments')}>댓글</Tab>
      </TabContainer>
      {activeTab === 'reviews' ? (
        <Table>
          <thead>
            <tr>
              <Th>제목</Th>
              <Th>작성자</Th>
              <Th>작성일</Th>
              <Th>액션</Th>
            </tr>
          </thead>
          <tbody>
            {reviews.map(review => (
              <tr key={review._id}>
                <Td>{review.title}</Td>
                <Td>{review.author?.name || '알 수 없음'}</Td>
                <Td>{new Date(review.createdAt).toLocaleDateString()}</Td>
                <Td>
                  <ActionButton onClick={() => handleDelete(review._id, 'review')}>삭제</ActionButton>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Table>
          <thead>
            <tr>
              <Th>내용</Th>
              <Th>작성자</Th>
              <Th>리뷰</Th>
              <Th>작성일</Th>
              <Th>액션</Th>
            </tr>
          </thead>
          <tbody>
            {comments.map(comment => (
              <tr key={comment._id}>
                <Td>{comment.content}</Td>
                <Td>{comment.author?.name || '알 수 없음'}</Td>
                <Td>{comment.review?.title || '알 수 없음'}</Td>
                <Td>{new Date(comment.createdAt).toLocaleDateString()}</Td>
                <Td>
                  <ActionButton onClick={() => handleDelete(comment._id, 'comment')}>삭제</ActionButton>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ContentManagement;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// 스타일 컴포넌트 정의
const AddReviewContainer = styled.div`
  padding: 20px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
`;

const StarRating = styled.div`
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const categories = [
  { _id: '1', name: '전자제품' },
  { _id: '2', name: '의류' },
  { _id: '3', name: '식품' },
  { _id: '4', name: '자동차' },
  { _id: '5', name: '기타' },
];

const AddReviewPage = ({ onReviewAdded }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [rating, setRating] = useState(0);
  const [images, setImages] = useState([]);
  const [token, setToken] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error('토큰이 없습니다. 로그인이 필요합니다.');
    }
  }, []);

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('Refresh token not found');
    }

    const response = await fetch('http://localhost:4000/api/auth/refresh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken }),
    });

    if (!response.ok) {
      throw new Error('Failed to refresh token');
    }

    const data = await response.json();
    localStorage.setItem('token', data.token);
    setToken(data.token);
  };

  // 이미지 업로드 핸들러
  const handleImageUpload = (e) => {
    const selectedFiles = Array.from(e.target.files);
    
    if (images.length + selectedFiles.length > 5) {
      alert('최대 5장까지 업로드할 수 있습니다.');
      return;
    }

    setImages((prevImages) => [...prevImages, ...selectedFiles]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      alert('로그인이 필요합니다.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('category', selectedCategory);
    formData.append('rating', rating.toString());
    images.forEach((image) => formData.append('images', image));

    try {
      let response = await fetch('/api/reviews', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        await refreshToken();
        return handleSubmit(e);
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '리뷰 제출 중 오류가 발생했습니다.');
      }

      if (onReviewAdded) {
        onReviewAdded();
      }
      alert('리뷰가 성공적으로 남겨졌습니다!');
      resetForm();
    } catch (error) {
      alert(`리뷰 남기기 중 오류가 발생했습니다: ${error.message}`);
    }
  };

  const resetForm = () => {
    setTitle('');
    setContent('');
    setSelectedCategory('');
    setRating(0);
    setImages([]);
  };

  return (
    <AddReviewContainer>
      <h1>리뷰 남기기</h1>
      <form onSubmit={handleSubmit}>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          required
        >
          <option value="">카테고리 선택</option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </select>
        <InputField
          type="text"
          placeholder="제품 이름"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <StarRating>
          <label>별점: </label>
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              onClick={() => setRating(star)}
              style={{
                cursor: 'pointer',
                fontSize: '20px',
                color: star <= rating ? 'gold' : 'gray',
              }}
            >
              {star <= rating ? '★' : '☆'}
            </span>
          ))}
        </StarRating>
        <InputField
          type="file"
          multiple
          onChange={handleImageUpload}
          accept="image/*"
        />
        <TextArea
          placeholder="리뷰 내용"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />
        <Button type="submit">리뷰 남기기</Button>
      </form>
      {images.length > 0 && (
        <div>
          <h3>업로드된 이미지 미리보기:</h3>
          {images.map((image, index) => (
            <img
              key={index}
              src={URL.createObjectURL(image)}
              alt="Preview"
              style={{ width: '100px', margin: '5px' }}
            />
          ))}
        </div>
      )}
    </AddReviewContainer>
  );
};

export default AddReviewPage;
import React, { useState } from 'react';
import axios from 'axios';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post('/api/adminlogin', { email, password });
      if (response.data.token) {
        localStorage.setItem('adminToken', response.data.token);
        localStorage.setItem('adminRole', response.data.role);
        window.location.href = '/admindashboard';
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError(error.response?.data?.message || '로그인에 실패했습니다.');
    }
  };

  // 인증이 필요한 API 요청 함수
  const fetchProtectedData = async () => {
    const token = localStorage.getItem('adminToken');

    try {
      const response = await axios.get('/api/protected-endpoint', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Protected data:', response.data);
    } catch (error) {
      console.error('Error fetching protected data:', error);
    }
  };


  return (
    <form onSubmit={handleLogin}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="관리자 이메일"
        required
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="비밀번호"
        required
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button type="submit">로그인</button>
    </form>
  );
};

export default AdminLogin;
import React, { useEffect, useState , useCallback, useMemo} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaEnvelope, FaCalendarAlt, FaHeart, FaPen ,FaThumbsUp,FaUserMinus } from 'react-icons/fa';

const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
`;

const Header = styled.h2`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const UserInfoCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const UserName = styled.h3`
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
`;

const UserDetail = styled.p`
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const StatCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StatNumber = styled.h4`
  font-size: 2rem;
  color: #3498db;
  margin-bottom: 0.5rem;
`;

const StatLabel = styled.p`
  font-size: 1rem;
  color: #7f8c8d;
`;

const PostsSection = styled.div`
  margin-top: 2rem;
`;

const PostsList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const PostTitle = styled.h4`
  font-size: 1.2rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
`;

const PostMeta = styled.p`
  font-size: 0.9rem;
  color: #7f8c8d;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const Tab = styled.button`
  background-color: ${props => (props.active ? '#3498db' : '#f8f9fa')};
  color: ${props => (props.active ? '#fff' : '#333')};
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => (props.active ? '#2980b9' : '#e9ecef')};
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: #3498db;
  margin-top: 2rem;
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: #e74c3c;
  margin-top: 2rem;
`;

const PostItem = styled.li`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;
  transition: box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const DeleteAccountButton = styled.button`
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;

  &:hover {
    background-color: #c0392b;
  }
`;


const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [userReviews, setUserReviews] = useState([]);
  const [likedReviews, setLikedReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('userReviews');
  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('로그인이 필요합니다.');
      setLoading(false);
      return;
    }
  
    try {
      setLoading(true);
      const [userResponse, reviewsResponse, likedReviewsResponse] = await Promise.all([
        axios.get('/api/user', {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get('/api/user/reviews', {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get('/api/user/liked-reviews', {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
  
      setUser(userResponse.data);
      setUserReviews(reviewsResponse.data.reviews || []);
      setLikedReviews(likedReviewsResponse.data.reviews || []);
    } catch (error) {
      console.error('데이터를 불러오는 데 실패했습니다:', error.response || error);
      setError(`데이터를 불러오는 데 실패했습니다. ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleReviewClick = (reviewId) => {
    navigate(`/reviews/${reviewId}`);
  };

  const handleDeleteAccount = async () => {
    const isConfirmed = window.confirm("정말로 회원 탈퇴하시겠습니까? 이 작업은 되돌릴 수 없습니다.");
    if (!isConfirmed) return;
  
    const token = localStorage.getItem('token');
    if (!token) {
      setError('로그인이 필요합니다.');
      return;
    }
  
    try {
      await axios.delete('/api/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      localStorage.removeItem('token');
      
      // 로그인 상태 변경 이벤트 발생
      window.dispatchEvent(new Event('loginStateChanged'));
  
      alert('회원 탈퇴가 완료되었습니다.');
      navigate('/');
    } catch (error) {
      console.error('회원 탈퇴 중 오류 발생:', error.response || error);
      setError(`회원 탈퇴에 실패했습니다. ${error.response?.data?.message || error.message}`);
    }
  };

  // 평균 평점 계산
  const averageRating = useMemo(() => {
    if (userReviews.length === 0) return 'N/A';
    const totalRating = userReviews.reduce((sum, review) => sum + (review.rating || 0), 0);
    return (totalRating / userReviews.length).toFixed(1);
  }, [userReviews]);

  // 받은 좋아요 수 계산
  const totalReceivedLikes = useMemo(() => {
    return userReviews.reduce((sum, review) => sum + (review.likes?.length || 0), 0);
  }, [userReviews]);

  if (loading) return <LoadingMessage>데이터를 불러오는 중...</LoadingMessage>;
  if (error) return <ErrorMessage>{error}</ErrorMessage>;
  if (!user) return <ErrorMessage>사용자 정보를 찾을 수 없습니다.</ErrorMessage>;

  const displayReviews = activeTab === 'userReviews' ? userReviews : likedReviews;

  return (
    <DashboardContainer>
      <Header>마이페이지</Header>
      <UserInfoCard>
        <UserName>{user.name}</UserName>
        <UserDetail><FaEnvelope /> {user.email}</UserDetail>
        {user.createdAt && (
          <UserDetail>
          {/* }  <FaCalendarAlt /> 가입일: {new Date(user.createdAt).toLocaleDateString()} */}
          </UserDetail>
        )}
        <DeleteAccountButton onClick={handleDeleteAccount}>
          <FaUserMinus /> 회원 탈퇴
        </DeleteAccountButton>
      </UserInfoCard>
      
      <StatsGrid>
        <StatCard>
          <StatNumber>{userReviews.length}</StatNumber>
          <StatLabel>작성한 리뷰</StatLabel>
        </StatCard>
        <StatCard>
          <StatNumber>{likedReviews.length}</StatNumber>
          <StatLabel>좋아요한 리뷰</StatLabel>
        </StatCard>
        <StatCard>
          <StatNumber>{totalReceivedLikes}</StatNumber>
          <StatLabel>받은 좋아요</StatLabel>
        </StatCard>
        <StatCard>
          <StatNumber>{averageRating}</StatNumber>
          <StatLabel>평균 평점</StatLabel>
        </StatCard>
      </StatsGrid>

      <PostsSection>
        <TabContainer>
          <Tab 
            active={activeTab === 'userReviews'} 
            onClick={() => setActiveTab('userReviews')}
          >
            <FaPen /> 내가 작성한 리뷰 ({userReviews.length})
          </Tab>
          <Tab 
            active={activeTab === 'likedReviews'} 
            onClick={() => setActiveTab('likedReviews')}
          >
            <FaHeart /> 좋아요한 리뷰 ({likedReviews.length})
          </Tab>
        </TabContainer>

        <PostsList>
          {displayReviews.length > 0 ? (
            displayReviews.map((review) => (
              <PostItem key={review._id} onClick={() => handleReviewClick(review._id)}>
                <PostTitle>{review.title}</PostTitle>
                <PostMeta>
                  {activeTab === 'likedReviews' && `작성자: ${review.userId?.name || 'Unknown'} | `}
                  작성일: {new Date(review.createdAt).toLocaleDateString()} | 
                  <FaThumbsUp /> {review.likes?.length || 0} |
                  ⭐ {review.rating}
                </PostMeta>
              </PostItem>
            ))
          ) : (
            <p>{activeTab === 'userReviews' ? '작성한 리뷰가 없습니다.' : '좋아요한 리뷰가 없습니다.'}</p>
          )}
        </PostsList>
      </PostsSection>
    </DashboardContainer>
  );
};

export default Dashboard;
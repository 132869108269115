import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
`;

const Form = styled.form`
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  width: 400px;
  text-align: center;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  max-width: 350px;
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: 15px;
  font-size: 1rem;

  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 12px;
  font-size: 1rem;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
  max-width: 350px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #007bff;
    opacity: 0.7;
  }
`;

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (name === '') {
      setError('이름을 입력해주세요.');
      setLoading(false);
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError('유효한 이메일을 입력해주세요.');
      setLoading(false);
      return;
    }

    if (password.length < 6) {
      setError('비밀번호는 최소 6자 이상이어야 합니다.');
      setLoading(false);
      return;
    }
    if (!/[A-Z]/.test(password)) {
      setError('비밀번호에는 대문자가 포함되어야 합니다.');
      setLoading(false);
      return;
    }
    if (!/[!@#$%^&*]/.test(password)) {
      setError('비밀번호에는 특수문자가 포함되어야 합니다.');
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError('비밀번호가 일치하지 않습니다.');
      setLoading(false);
      return;
    }

    try {
      await axios.post('/api/register', { name, email, password });
      navigate('/login');
    } catch (error) {
      if (error.response) {
        // 서버가 응답을 보낸 경우
        switch (error.response.status) {
          case 400:
            setError(error.response.data.message || '유효하지 않은 요청입니다. 입력 값을 확인해주세요.');
            break;
          case 500:
            setError('서버에 문제가 발생했습니다. 나중에 다시 시도해주세요.');
            break;
          default:
            setError('회원가입 실패: ' + (error.response.data.message || '알 수 없는 오류'));
            break;
        }
      } else {
        // 서버가 응답하지 않은 경우
        setError('서버와 연결할 수 없습니다. 인터넷 연결을 확인하세요.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <h2>회원가입</h2>
      <Form onSubmit={handleSubmit}>
        <InputGroup>
          <Label>이름:</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputGroup>
        <InputGroup>
          <Label>이메일:</Label>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </InputGroup>
        <InputGroup>
          <Label>비밀번호:</Label>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputGroup>
        <InputGroup>
          <Label>비밀번호 확인:</Label>
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </InputGroup>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <SubmitButton type="submit" disabled={loading}>
          {loading ? '가입 중...' : '회원가입'}
        </SubmitButton>
      </Form>
    </Container>
  );
};

export default Register;
import React, { useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import ContentManagement from './component/ContentManagement';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  Title, 
  Tooltip, 
  Legend 
} from 'chart.js';

ChartJS.register(
  CategoryScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  Title, 
  Tooltip, 
  Legend
);

const DashboardContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const PageTitle = styled.h1`
  color: #333;
  margin-bottom: 20px;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  padding: 10px 20px;
  background-color: ${props => props.active ? '#007bff' : '#f0f0f0'};
  color: ${props => props.active ? 'white' : 'black'};
  border: none;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
`;

const StatCard = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
`;

const StatLabel = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 5px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 5px;
  }
`;

const ReportTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const ReportTh = styled.th`
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: left;
`;

const ReportTd = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
`;


const AdminDashboard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('overview');
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalReviews: 0,
    totalReports: 0,
  });
  const [recentReports, setRecentReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [reports, setReports] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: '일일 신규 사용자',
      data: [],
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  });

  const fetchDashboardData = useCallback(async () => {
    const token = localStorage.getItem('adminToken');
    try {
      if (!token) {
        throw new Error('No admin token found');
      }
      const response = await axios.get('/api/admin/dashboard', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Dashboard data:', JSON.stringify(response.data, null, 2));
      setStats(response.data.stats);
      setRecentReports(response.data.recentReports);
      setUsers(response.data.users);
      setReviews(response.data.reviews);
      
      if (response.data.chartData && response.data.chartData.labels && response.data.chartData.newUsers) {
        setChartData({
          labels: response.data.chartData.labels,
          datasets: [{
            label: '일일 신규 사용자',
            data: response.data.chartData.newUsers,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }]
        });
      } else {
        console.error('Invalid chart data received:', response.data.chartData);
      }

      await fetchReports();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('adminToken');
        navigate('/admin/login');
      }
      console.error('Error fetching dashboard data:', error);
    }
  }, [navigate]);

  const fetchReports = useCallback(async () => {
    const token = localStorage.getItem('adminToken');
    try {
      const response = await axios.get('/api/admin/reports', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Fetched reports:', JSON.stringify(response.data, null, 2));
      setReports(response.data);
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  }, []);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const handleReportAction = useCallback(async (reportId, action) => {
    const token = localStorage.getItem('adminToken');
    try {
      await axios.post(`/api/admin/reports/${reportId}/${action}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchDashboardData();
    } catch (error) {
      console.error('Error handling report action:', error);
    }
  }, [fetchDashboardData]);

  const handleUserAction = useCallback(async (userId, action) => {
    const token = localStorage.getItem('adminToken');
    try {
      await axios.post(`/api/admin/users/${userId}/${action}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchDashboardData();
    } catch (error) {
      console.error('Error handling user action:', error);
    }
  }, [fetchDashboardData]);

  const handleReviewAction = useCallback(async (reviewId, action) => {
    const token = localStorage.getItem('adminToken');
    try {
      await axios.post(`/api/admin/reviews/${reviewId}/${action}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchDashboardData();
    } catch (error) {
      console.error('Error handling review action:', error);
    }
  }, [fetchDashboardData]);

  const getReportTargetType = useCallback((report) => {
    if (report.reportedReview) return '리뷰';
    if (report.reportedComment) return '댓글';
    return report.type || '알 수 없음';
  }, []);
  
  const getReportTargetId = useCallback((report) => {
    if (report.reportedReview) return report.reportedReview._id || '알 수 없음';
    if (report.reportedComment) return report.reportedComment._id || '알 수 없음';
    return report.targetId || '알 수 없음';
  }, []);
  
  const getReportTargetContent = useCallback((report) => {
    if (report.reportedReview) return report.reportedReview.title || '내용 없음';
    if (report.reportedComment) return report.reportedComment.content || '내용 없음';
    return '내용 없음';
  }, []);

  const handleDeleteContent = useCallback(async (report) => {
    const token = localStorage.getItem('adminToken');
    const contentType = getReportTargetType(report);
    const contentId = getReportTargetId(report);
    
    if (window.confirm(`정말로 이 ${contentType}을(를) 삭제하시겠습니까?`)) {
      try {
        let response;
        if (contentType === '리뷰') {
          response = await axios.delete(`/api/admin/reviews/${contentId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
        } else if (contentType === '댓글') {
          const reviewId = report.reportedComment?.review;
          if (!reviewId) {
            throw new Error('리뷰 ID를 찾을 수 없습니다.');
          }
          response = await axios.delete(`/api/admin/reviews/${reviewId}/comments/${contentId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
        } else {
          throw new Error('알 수 없는 컨텐츠 타입입니다.');
        }

        if (response.status === 200) {
          alert(`${contentType}이(가) 삭제되었습니다.`);
          fetchReports();
        } else {
          throw new Error(`${contentType} 삭제에 실패했습니다.`);
        }
      } catch (error) {
        console.error(`Error deleting ${contentType}:`, error);
        alert(`${contentType} 삭제 중 오류가 발생했습니다: ${error.message}`);
      }
    }
  }, [getReportTargetType, getReportTargetId, fetchReports]);
  
  const handleNavigateToContent = useCallback((report) => {
    const contentType = getReportTargetType(report);
    const contentId = getReportTargetId(report);
    
    if (contentType === '리뷰') {
      navigate(`/reviews/${contentId}`);
    } else if (contentType === '댓글') {
      let reviewId;
      if (report.reportedComment && report.reportedComment.review) {
        reviewId = report.reportedComment.review._id || report.reportedComment.review;
      }
      
      if (!reviewId) {
        console.error('Invalid review ID for comment', report);
        alert('댓글에 해당하는 리뷰 정보를 찾을 수 없습니다.');
        return;
      }
      navigate(`/reviews/${reviewId}`, { state: { scrollToComment: contentId } });
    } else {
      console.error('Unknown content type', report);
      alert('알 수 없는 컨텐츠 타입입니다.');
    }
  }, [navigate, getReportTargetType, getReportTargetId]);

  const handleDeleteComment = useCallback(async (report) => {
    console.log('Report object:', JSON.stringify(report, null, 2));
  
    const token = localStorage.getItem('adminToken');
    if (!token) {
      console.error('Admin token not found');
      alert('관리자 인증 토큰이 없습니다. 다시 로그인해주세요.');
      return;
    }
  
    let commentId, reviewId;
  
    // 리포트 타입과 데이터 구조 확인
    if (report.type === 'comment' && report.reportedComment) {
      commentId = report.reportedComment._id;
      reviewId = report.reportedComment.review?._id || report.reportedComment.review;
    } else {
      console.error('Invalid report data structure', { report });
      alert('리포트 데이터 구조가 올바르지 않습니다.');
      return;
    }
  
    if (!commentId) {
      console.error('Invalid comment ID', { report });
      alert('댓글 정보를 찾을 수 없습니다.');
      return;
    }
  
    if (!reviewId) {
      console.error('Review ID not found', { report });
      alert('댓글에 해당하는 리뷰 정보를 찾을 수 없습니다.');
      return;
    }
  
    if (window.confirm('정말로 이 댓글을 삭제하시겠습니까?')) {
      try {
        const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const response = await axios.delete(`${baseURL}/api/admin/reviews/${reviewId}/comments/${commentId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
  
        if (response.status === 200) {
          alert('댓글이 삭제되었습니다.');
          fetchReports();
        } else {
          console.error('Unexpected response status:', response.status);
          alert(`댓글 삭제에 실패했습니다. 상태 코드: ${response.status}`);
        }
      } catch (error) {
        console.error('Error deleting comment:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
        alert(`댓글 삭제 중 오류가 발생했습니다: ${error.message}`);
      }
    }
  }, [fetchReports]);


  return (
    <DashboardContainer>
      <PageTitle>관리자 대시보드</PageTitle>
      <TabContainer>
        <Tab active={activeTab === 'overview'} onClick={() => setActiveTab('overview')}>개요</Tab>
        <Tab active={activeTab === 'reports'} onClick={() => setActiveTab('reports')}>신고</Tab>
        <Tab active={activeTab === 'users'} onClick={() => setActiveTab('users')}>사용자</Tab>
        <Tab active={activeTab === 'reviews'} onClick={() => setActiveTab('reviews')}>리뷰</Tab>
        <Tab active={activeTab === 'content'} onClick={() => setActiveTab('content')}>컨텐츠 관리</Tab>
      </TabContainer>

      {activeTab === 'overview' && (
        <>
          <StatGrid>
            <StatCard>
              <StatNumber>{stats.totalUsers}</StatNumber>
              <StatLabel>총 사용자 수</StatLabel>
            </StatCard>
            <StatCard>
              <StatNumber>{stats.totalReviews}</StatNumber>
              <StatLabel>총 리뷰 수</StatLabel>
            </StatCard>
            <StatCard>
              <StatNumber>{stats.totalReports}</StatNumber>
              <StatLabel>총 신고 수</StatLabel>
            </StatCard>
          </StatGrid>
          {chartData.labels.length > 0 && <Line data={chartData} />}
        </>
      )}

      {activeTab === 'reports' && (
        <ReportTable>
          <thead>
            <tr>
              <ReportTh>ID</ReportTh>
              <ReportTh>신고 대상</ReportTh>
              <ReportTh>신고 대상 내용</ReportTh>
              <ReportTh>신고 사유</ReportTh>
              <ReportTh>신고자</ReportTh>
              <ReportTh>액션</ReportTh>
            </tr>
          </thead>
          <tbody>
            {reports.length > 0 ? (
              reports.map(report => (
                <tr key={report._id}>
                  <ReportTd>{report._id}</ReportTd>
                  <ReportTd>{getReportTargetType(report)}: {getReportTargetId(report)}</ReportTd>
                  <ReportTd>{getReportTargetContent(report)}</ReportTd>
                  <ReportTd>{report.reason}</ReportTd>
                  <ReportTd>{report.reporter?.username || '알 수 없음'}</ReportTd>
                  <ReportTd>
                  {getReportTargetType(report) === '리뷰' ? (
                  <ActionButton onClick={() => handleDeleteContent(report)}>리뷰 삭제</ActionButton>
                  ) : getReportTargetType(report) === '댓글' ? (
                  <ActionButton onClick={() => handleDeleteComment(report)}>댓글 삭제</ActionButton>
                  ) : (
                   <ActionButton disabled>알 수 없음</ActionButton>
                  )}
                 <ActionButton onClick={() => handleNavigateToContent(report)}>이동</ActionButton>
                  </ReportTd>
                </tr>
              ))
            ) : (
              <tr>
                <ReportTd colSpan="6">신고 내역이 없습니다.</ReportTd>
              </tr>
            )}
          </tbody>
        </ReportTable>
      )}

      {activeTab === 'users' && (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>이름</Th>
                <Th>이메일</Th>
                <Th>가입일</Th>
                <Th>상태</Th>
                <Th>작업</Th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user._id}>
                  <Td>{user._id}</Td>
                  <Td>{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>{new Date(user.createdAt).toLocaleDateString()}</Td>
                  <Td>{user.status}</Td>
                  <Td>
                    <ActionButton onClick={() => handleUserAction(user._id, 'suspend')}>정지</ActionButton>
                    <ActionButton onClick={() => handleUserAction(user._id, 'activate')}>활성화</ActionButton>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}

      {activeTab === 'reviews' && (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>제목</Th>
                <Th>작성자</Th>
                <Th>작성일</Th>
                <Th>평점</Th>
                <Th>작업</Th>
              </tr>
            </thead>
            <tbody>
              {reviews.map(review => (
                <tr key={review._id}>
                  <Td>{review._id}</Td>
                  <Td>{review.title}</Td>
                  <Td>{review.author?.name || '알 수 없음'}</Td>
                  <Td>{new Date(review.createdAt).toLocaleDateString()}</Td>
                  <Td>{review.rating}</Td>
                  <Td>
                    <ActionButton onClick={() => handleReviewAction(review._id, 'delete')}>삭제</ActionButton>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
      {activeTab === 'content' && <ContentManagement />}
    </DashboardContainer>
  );
};

export default AdminDashboard;